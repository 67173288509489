<template>
  <MPZModal @close="$emit('close')">
    <div class="content">
      <svg @click="$emit('close')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="#A1A1A1" stroke-width="2"/>
      </svg>

      <h3 class="title-3">Заказать счет</h3>

      <div class="input">
        <MPZInput
          v-model="summ"
          placeholder="Введите сумму"
          :error="error"
          type="number"
        />
      </div>

      <div class="form-item">
        <textarea
          v-model="comment"
          placeholder="Комментарий(примерный тоннаж, количество заездов)"
          maxlength="90"
        ></textarea>
      </div>

      <div class="call-btn">
        <MPZButton
          label="Отправить"
          @clicked="sendForm"
        />
      </div>
    </div>

    <MPZPreloader v-if="isPreloader"/>
  </MPZModal>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import MPZModal from '@/components/UI/MPZModal.vue'
import MPZPreloader from '@/components/common/MPZPreloader.vue'
import useApi from '@/services/useApi'

const emit = defineEmits(['close', 'successSended'])

const props = defineProps({
  contractID: {
    type: Number ,
    required: true
  }
})

const summ = ref('')
const comment = ref('')
const error = ref('')

const isPreloader = ref(false)

const sendForm = async() => {
  isPreloader.value = true
  error.value = ''

  if(summ.value === '' || summ.value[0] === '0'){
    error.value = 'Введите корректное значение'
    return
  } else {
    const req = await useApi('post', 'request/invoice', {
      contract_id: props.contractID,
      summ: summ.value,
      comment: comment.value
    })
    if(req.status === 'successful'){
      isPreloader.value = false
      emit('close')
      emit('successSended')
    }
  }
}
</script>

<style scoped lang="scss">
.content{
  padding: 20px 12px 0px 12px;
  position: relative;

  .input{
    position: relative;
    &:before{
      content: '₽';
      position: absolute;
      right: 0;
      top: 13px;
    }
  }

  :deep(input){
    padding-right: 20px;
  }

  svg{
    position: absolute;
    right: 5px;
    top: -5px;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.call-btn{
  width: fit-content;
  margin-top: 30px;
}
h3{
  margin-bottom: 20px;
}
p{
  opacity: 0.5;
  margin-bottom: 20px;
}

textarea{
  height: 66px;
  margin-top: 20px;
}
</style>
