<template>
  <MPZModal @close="$emit('close')">
    <div class="content">
      <svg @click="$emit('close')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="#A1A1A1" stroke-width="2"/>
      </svg>

      <h3 class="title-3">Заказать звонок</h3>
      <p>Проверьте номер телефона для звонка или поменяйте его</p>
      <MPZInput
        v-model="phone"
        placeholder="Номер телефона"
        masked="tel"
        :error="error"
      />
      <div class="call-btn">
        <MPZButton
          label="Далее"
          @clicked="sendForm"
        />
      </div>
    </div>
  </MPZModal>
</template>

<script setup>
import { defineEmits, ref, onMounted } from 'vue'
import MPZModal from '@/components/UI/MPZModal.vue'
import store from '@/store'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const emit = defineEmits(['close', 'successSended'])

const phone = ref('')
const error = ref('')

onMounted(()=> {
  if(store.state.user.token){
    phone.value = store.state.user.phone
  }
})

const sendForm = async() => {
  error.value = ''

  if(phone.value.length !== 17){
    error.value = 'Введите телефон'
    return
  }

  const req = await useApi('post', 'request/callback', {
    phone: formattedPhone(phone.value)
  })
  emit('successSended')
}
</script>

<style scoped lang="scss">
.content{
  padding: 20px 12px 0px 12px;
  position: relative;

  svg{
    position: absolute;
    right: 5px;
    top: -5px;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.call-btn{
  width: fit-content;
  margin-top: 30px;
}
h3{
  margin-bottom: 20px;
}
p{
  opacity: 0.5;
  margin-bottom: 20px;
}
// .title-4{
//   margin: 30px 0;
//   text-align: center;
// }
// .button{
//   text-align: center;
// }
</style>
