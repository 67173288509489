<template>
  <div class="page">
    <!-- +79998887766 -->

    <div class="container">
      <div class="title-2 ">Профиль {{ $store.state.user.company }}</div>
    </div>

    <div class="subheader-row container">
      <div class="select">
        <div class="label">Договор</div>
        <MPZSelect
          :items="contractsList"
          @changeSelect="contractSelected = $event"
          :selectedItem="contractSelected"
          :maxHeight="225"
        />
      </div>
      <div class="call-back" @click="isShowModalCallback = true">Заказать звонок</div>
    </div>

    <div
      class="container"
      v-if="actsList && balanceInfo"
    >
      <div class="content">
        <div class="table">
          <div class="table-title">
            <span>Взвешивания в текущем месяце</span>
            <img src="@/assets/images/sort_icon.svg" alt="">
          </div>
          <div class="table-header">
            <div class="table-row">
              <span>Дата и время</span>
              <span>ID акта</span>
              <span>Принятый вес</span>
              <span>Стоимость</span>
            </div>
          </div>
          <div class="table-body">
            <div
              class="table-row"
              v-for="act in actsList"
              :key="act.id"
            >
              <span class="date">
                <p>{{moment(act.date).utc().format('DD.MM.YYYY')}}</p>
                <p>{{moment(act.date).utc().format('・HH:mm')}}</p>
              </span>
              <span>{{act.number}}</span>
              <span>{{act.tonnage}} тн</span>
              <span>{{act.summ}} <template v-if="act.summ !== '-'">₽</template> </span>
            </div>

            <div v-if="!actsList.length" class="empty">Актов пока нет</div>
          </div>
        </div>
        <div class="aside">
          <div class="aside-block">
            <div class="aside-block__title" v-if="balanceInfo.summ_last !== '-' && balanceInfo.summ_last.includes('-')">Баланс предыдущего <br/> месяца</div>
            <div
              v-if="balanceInfo.summ_last !== '-' && balanceInfo.summ_last.includes('-')"
              class="aside-block__price prev-month"
              :class="{green: !balanceInfo.summ_last.includes('-')}"
            >
              {{ balanceInfo.summ_last }} ₽
            </div>

            <div class="aside-block__title">Баланс текущего месяца</div>

            <div
              class="aside-block__price "
              :class="{green: !balanceInfo.summ_current.includes('-'), black: balanceInfo.summ_current === '-'}"
            >
              {{ balanceInfo.summ_current }} <template v-if="balanceInfo.summ_current !== '-'">₽</template>
            </div>

            <div class="aside-block__btn">
              <MPZButton
                label="Заказать счет"
                @click="isShowMPZModalOrderBill = true"
              />
            </div>
            <div class="aside-block__title">Дата оплаты</div>
            <div class="aside-block__date">{{balanceInfo.pay_date == '-' ? '-'  : moment(balanceInfo.pay_date).utc().format('DD.MM.YYYY')}}</div>

          </div>
          <div class="aside-block">
            <div class="aside-block__title">Принятый вес в тек. месяце</div>
            <div class="aside-block__weight">{{ balanceInfo.tonnage }} тн</div>
            <MPZButton
              label="Справка по видам отходов"
              @click="getReport"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="emptyContracts" class="empty-contracts container">
      <span>Данных для отображения нет. Свяжитесь с нами</span>
      <MPZButton
        label="Контакты"
        @click="$router.push('/contacts')"
      />
    </div>

    <transition name="default">
      <MPZModalCallback
        v-if="isShowModalCallback"
        @close="isShowModalCallback = false"
        @successSended="successSended"
      />
    </transition>

    <transition name="default">
      <MPZModalSendForm
        v-if="isShowMPZModalSendForm"
        @close="isShowMPZModalSendForm = false"
      />
    </transition>

    <transition name="default">
      <MPZModalAccountProcess
        v-if="isShowMPZModalAccountProcess"
        @close="isShowMPZModalAccountProcess = false"
      />
    </transition>

    <transition name="default">
      <MPZModalReport
        v-if="isShowMPZModalReport"
        @close="isShowMPZModalReport = false"
        @success="isShowMPZModalSendFormProcessing = true"
        :contract="contractSelected.id"
      />
    </transition>

    <transition name="default">
      <MPZModalReportFailQuartal
        v-if="isShowMPZModalReportFailQuartal"
        @close="isShowMPZModalReportFailQuartal = false"
      />
    </transition>

    <transition name="default">
      <MPZModalOrderBill
        v-if="isShowMPZModalOrderBill"
        @close="isShowMPZModalOrderBill = false"
        :contractID="contractSelected.id"
        @successSended="isShowMPZModalSendFormBill = true"
      />
    </transition>

    <transition name="default">
      <MPZModalSendForm
        v-if="isShowMPZModalSendFormBill"
        @close="isShowMPZModalSendFormBill = false"
        text="Мы отправим счет на почту в течении дня"
      />
    </transition>

    <transition name="default">
      <MPZModalSendForm
        v-if="isShowMPZModalSendFormProcessing"
        @close="isShowMPZModalSendFormProcessing = false"
        text="Ваши изменения сохранены. Наш эколог проверит их и свяжется с вами"
      />
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, reactive, onMounted, computed } from 'vue'
import MPZModalCallback from '@/components/modals/MPZModalCallback.vue'
import MPZModalSendForm from '@/components/modals/MPZModalSendForm.vue'
import MPZModalAccountProcess from '@/components/modals/MPZModalAccountProcess.vue'
import MPZModalReport from '@/components/modals/MPZModalReport.vue'
import MPZModalOrderBill from '@/components/modals/MPZModalOrderBill.vue'

import MPZModalReportFailQuartal from '@/components/modals/MPZModalReportFailQuartal.vue'
import MPZSelect from '@/components/UI/MPZSelect.vue'
import useApi from '@/services/useApi'
import moment from 'moment'
import store from '@/store'
import { useRouter } from 'vue-router'

const router = useRouter()

const actsList = ref(null)
const balanceInfo = ref(null)

const isShowMPZModalAccountProcess = ref(false)
const isShowMPZModalReportFailQuartal = ref(false)
const isShowMPZModalOrderBill = ref(false)
const isShowMPZModalSendFormBill = ref(false)
const isShowMPZModalSendFormProcessing = ref(false)

const contractsList = ref([])
const contractSelected = ref({id: '', label: '-'})

const getContractInfo = async(id) => {
  const acts = await useApi('get', `company/acts/${id}`)
  actsList.value = acts.acts

  const balance = await useApi('get', `company/balance/${id}`)
  balanceInfo.value = balance.balance
}

const emptyContracts = ref(false)

const getReport = () => {
  isShowMPZModalReport.value = true

  // const currentMonth = new Date().getMonth()
  // const currentDay = new Date().getDate()

  // console.log(contractsList.value)

  // console.log(contractSelected.value.id)

  // if((currentMonth == 0 || currentMonth == 3 || currentMonth == 6|| currentMonth == 9) && (currentDay >= 5 && currentDay <= 15)){
  //   isShowMPZModalReport.value = true
  // } else {
  //   isShowMPZModalReportFailQuartal.value = true
  // }
}

onMounted(async()=> {
  if(!store.state.user.token){
    router.push('/')
    return
  }

  const contracts = await useApi('get', 'company/contracts')
  if(contracts.contracts.length){
    contractsList.value = contracts.contracts.map(item => {
      return {
        id: item.id,
        label: item.name,
      }
    })
    contractSelected.value = contractsList.value[0]
  } else {
    emptyContracts.value = true
  }
})

watch(
  () => contractSelected.value,
  () => getContractInfo(contractSelected.value.id)
)

const isShowModalCallback = ref(false)
const isShowMPZModalSendForm = ref(false)
const isShowMPZModalReport = ref(false)

const successSended = () => {
  isShowModalCallback.value = false
  isShowMPZModalSendForm.value = true
}
</script>

<style scoped lang="scss">
.subheader-row{
  margin-top: 30px;
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 40px;

  @media (max-width: 500px){
    flex-direction: column;
    width: fit-content;
    align-items: flex-start;
    width: -webkit-fill-available;
  }
  .select{
    width: 300px;
    .label{
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 4px;
      font-family: "SFProDisplay-Medium";
    }
  }
  .call-back{
    font-size: 16px;
    line-height: 19px;
    position: relative;
    margin-top: 20px;
    cursor: pointer;
    height: fit-content;
    &:before{
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      content: '';
      height: 1px;
      background: #212121;
    }
    @media (max-width: 500px){
      margin-top: 30px;
    }
  }
}
.title-2{
  margin-bottom: 30px;
  margin-top: 20px;
  max-width: 600px;
  margin-left: 0;
}
.content{
  display: flex;
  align-items: flex-start;
  gap: 0 20px;
  @media (max-width: 1200px){
    flex-direction: column-reverse;
  }
}
.table{
  width: 796px;
  border: 1px solid #EAEAEA;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 1200px){
    width: 100%;
  }
  &-title{
    padding: 20px;
    border-bottom: 1px solid #EAEAEA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      font-family: "SFProDisplay-Medium";
    }
    img{
      cursor: pointer;
      opacity: 0;
    }
  }
  &-header{
    background:  #F7F7F7;
    border-bottom: 1px solid #EAEAEA;
    @media (max-width: 780px){
      display: none;
    }
  }
  &-body{
    max-height: 520px;
    overflow-y: auto;

    .empty{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 360px;
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #161616;
      opacity: 0.45;
      font-family: "SFProDisplay-Medium";
    }
    .table-row{
      border-bottom: 1px solid #EAEAEA;
      &:last-child{
        border-bottom: none;
      }
      span{
        display: flex;
        font-size: 16px;
        p{
          font-size: 16px;
          font-family: "SFProDisplay-Medium";
          &:first-child{
            width: 90px;
          }
        }
      }
    }
  }
  &-row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    span{
      padding: 14px 20px;
      width: 25%;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      font-family: "SFProDisplay-Medium";
      @media (max-width: 780px){
        width: 50%;
        &:nth-child(1){
          order: 3
        }
        &:nth-child(2){
          order: 1;
          padding-bottom: 0;
        }
        &:nth-child(3){
          order: 2;
          padding-bottom: 0;
        }
        &:nth-child(4){
          order: 4
        }
      }
    }
  }
}

.aside{
  width: 385px;
  @media (max-width: 1200px){
    display: flex;
    width: 100%;
    gap: 0 20px;
  }
  @media (max-width: 780px){
    flex-direction: column;
  }
  &-block{
    border: 1px solid #EAEAEA;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 10px;
    @media (max-width: 1200px){
      width: 50%;
      margin-bottom: 20px;
    }
    @media (max-width: 780px){
      width: 100%;
    }
    &__title{
      font-size: 24px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #161616;
      opacity: 0.45;
      margin-bottom: 12px;
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
    }
    &__price{
      color: #EC3421;
      font-size: 36px;
      line-height: 90.34%;
      letter-spacing: -0.02em;
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      &.green{
        color: #3EC96D;
      }
      &.black{
        color: #212121;
      }
      &.prev-month{
        margin-bottom: 20px;
      }
    }
    &__btn{
      margin-top: 13px;
      margin-bottom: 20px;
    }
    &__date{
      font-size: 20px;
      line-height: 120%;
      font-family: "SFProDisplay-Medium";
    }
    &__weight{
      font-size: 36px;
      line-height: 90.34%;
      font-family: "SFProDisplay-Medium";
      margin-bottom: 20px;
    }
  }
}

.empty-contracts{
  max-width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 330px;
  span{
    font-weight: 500;
    font-family: "SFProDisplay-Medium";
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #161616;
    opacity: 0.45;
    margin-bottom: 30px;
  }
}
</style>
