<template>
  <div
    class="q-select"
    ref="target"
  >
    <div
      class="q-select__label"
      :class="{clearValue}"
      @click="isOpenSelect = !isOpenSelect"
    >
      <span>{{selectedItem.label}}</span>
      <img
        src="@/assets/images/arrow.svg"
        alt="открыть/закрыть селект"
        class="q-select__arrow"
        :class="{active: isOpenSelect}"
      >
    </div>
    <transition name="up">
      <div
        class="q-select__list"
        v-if="isOpenSelect"
      >
        <ul :style="{maxHeight: maxHeight+'px'}">
          <li
            class="q-select__item"
            v-for="item in items"
            :key="item.value"
            @click="setValue(item)"
            :class="{active: selectedItem.label === item.label}"
          >
            {{ item.label }}
          </li>

          <li
            class="q-select__item q-select__item--empty"
            v-if="emptyMessage.length"
            @click="setValue({id: '', label: 'Не выбрано'})"
          >
            {{emptyMessage}}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  selectedItem: {
    type: Object,
  },
  maxHeight: {
    type: Number,
  },
  clearValue: {
    type: Boolean,
    default: false
  },
  emptyMessage: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['changeSelect'])

const isOpenSelect = ref(false)

const target = ref(null)

onClickOutside(target, (event) => isOpenSelect.value = false)

const setValue = item => {
  isOpenSelect.value = false
  emit('changeSelect', item)
}
</script>

<style scoped lang="scss">
.q-select{
  position: relative;

  &__label{
    height: 40px;
    background: white;
    padding: 10px 40px 10px 0px;
    cursor: pointer;
    border-bottom: 1px solid #A1A1A1;
    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #212121;
    }
    &.clearValue{
      padding: 8px 60px 8px 18px;
    }
  }

  &__arrow{
    position: absolute;
    right: 12px;
    top: 13px;
    transition: ease .2s;
    transform: rotate(180deg);

    &.active{
      transform: rotate(0);
    }
  }

  &__list{
    position: absolute;
    top: 45px;
    right: 0;
    width: 100%;
    padding: 10px;
    background: #F2F2F2;
    border-radius: 20px;
    z-index: 2;
    background: white;
    box-shadow: 0px 4px 22px -4px rgba(0, 0, 0, 0.14);
    ul{
      overflow-y: auto;
    }
  }
  &__item{
    overflow: hidden;
    margin-bottom: 0px;
    padding: 8px 10px;
    border-radius: 10px;
    font-size: 14px;
    transition: ease .3s;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:last-child{
      margin-bottom: 0;
    }
    &--empty{
      background: #F2F2F2;
    }
    &.active{
      opacity: .3;
    }
    &:hover{
      background: #F2F2F2;
    }
  }


  .up-enter-active,
  .up-leave-active {
    transition: .2s ease;
  }

  .up-enter-from,
  .up-leave-to {
    transform: translateY(-20px);
    opacity: 0;
  }
}

.clear-value{
  position: absolute;
  right: 40px;
  top: 11px;
  cursor: pointer;
}
</style>
