<template>
  <MPZModal @close="$emit('close')" v-if="isShowModal">
    <svg @click="$emit('close')" class="close-modal" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="#A1A1A1" stroke-width="2"/>
    </svg>

    <h4 class="title-3">Справка по видам отходов</h4>

    <div class="subtitle">Тут вы можете скорректировать объем привезенного в этом периоде тоннажа. Виды отходов указаны в соотвествии с договором.</div>

    <div
      class="select"
      v-if="currentQuartal === 1"
    >
      <MPZSelect
        :items="quarterList"
        @changeSelect="quarterSelected = $event"
        :selectedItem="quarterSelected"
      />
    </div>

    <div class="quartal-title" v-else>
      <span>Период</span>
      <span>{{ currentQuartal - 1 }} квартал</span>
    </div>

    <div class="question-list" v-if="form.questions">
      <div class="question-item"
        v-for="(input, idx) in form.questions"
        :key="input.id"
      >
        <div class="question-item__title">{{ form.questions[idx].label }}</div>
        <div class="question-item__code">
          Код по ФККО:
          <input type="text" v-maska="'# ## ### ## ## #'" :value="form.questions[idx].key">
        </div>
        <MPZInput
          type="number"
          v-model="form.questions[idx].tonnage"
          placeholder="Введите тоннаж"
          :error="form.questions[idx].error"
        />
      </div>
    </div>

    <div v-if="!form.questions.length" class="no-data">
      <div class="title-3">Данных для отображения нет. <br/> Свяжитесь с нами</div>
      <MPZButton
        label="Контакты"
        @clicked="goToContacts"
      />
    </div>

    <!-- Возможные ошибки в полях ввода тоннажа:
    1) Незаполненное поле. Подсказка под полем. “Заполните поле или поставьте 0”
    2) Сумма всех полей не равна тоннажу за период. Тоннаж пользователя больше. “Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Превышает тоннаж за выбранный период N тн”
    Отображается внизу модалки
    3) Сумма всех полей не равна тоннажу за период. Тоннаж пользователя меньше. “Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Распределите еще 1,4 тн”
    Отображается внизу модалки -->

    <!-- <div class="tonnage-error"> {{ summError }}</div> -->

    <div v-if="form.questions.length" class="period title-4">
        В этом периоде завезено: {{ currentReport.tonnage.toFixed(3) }} тн <br/>

      <span class="tonnage-error" v-if="(currentReport.tonnage.toFixed(3) - summ) > 0">
        Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Распределите еще {{currentReport.tonnage.toFixed(3) - summ }} тн
      </span >
      <span class="tonnage-error" v-else-if="(currentReport.tonnage.toFixed(3) - summ) < 0">
        Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Превышает тоннаж за выбранный период на {{ (currentReport.tonnage.toFixed(3) - summ) * -1 }} тн
      </span>
    </div>

    <MPZButton
      label="Отправить"
      @clicked="sendForm"
    />

    <transition name="default">
      <MPZPreloader v-if="isPreloader"/>
    </transition>
  </MPZModal>
</template>

<script setup>
import { defineEmits, ref, defineProps, onMounted, reactive, computed, watch } from 'vue'
import MPZModal from '@/components/UI/MPZModal.vue'
import MPZSelect from '@/components/UI/MPZSelect.vue'
import MPZPreloader from '@/components/MPZPreloader.vue'
import { useRouter } from 'vue-router'
import useApi from '@/services/useApi'
import { maska as vMaska } from 'maska'

const props = defineProps({
  contract: {
    type: Number,
    required: true,
  },
})

const isShowModal = ref(false)

const router = useRouter()

const emit = defineEmits(['close', 'success'])

const goToContacts = () => {
  router.push('/contacts')
  emit('close')
}

const form = reactive({
  questions: []
})

const isPreloader = ref(false)

const summError = ref('')

const reports = ref(null)

const currentReport = ref(null)

const currentQuartal = ref(null)

const summ = computed(()=> form.questions.reduce(function(sum, current) {
  return sum + Number(current.tonnage);
}, 0).toFixed(3))

onMounted(async()=> {
  let currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()

  // console.log('currentMonth', currentMonth)
  // console.log('currentYear', currentYear)


  // if(currentMonth == 0){
  //   currentQuartal.value = 1
  //   // за прошлый год
  //   currentYear = currentYear - 1
  // }
  // else if(currentMonth == 3){
  //   currentQuartal.value = 2
  // }
  // else if(currentMonth == 6){
  //   currentQuartal.value = 3
  // }
  // else{
  //   currentQuartal.value = 4
  // }

  // CHECKING
  currentQuartal.value = 2

  // console.log(`ГОД: ${currentYear}`, `КВАРТАЛ: ${currentQuartal.value}`)


  const req = await useApi('get', `company/report/${props.contract}/${currentYear}`)

  reports.value = req.report.reports

  // console.log(req.report.reports)

  reports.value.forEach(item => {
    item.distribution.forEach(el => el.error = '')
  })

  if(currentQuartal.value === 1){
    quarterSelected.value = {
      id: 4,
      label: '4 квартал'
    }
  } else {
    quarterSelected.value = {
      id: currentQuartal.value - 1,
      label: `${currentQuartal.value - 1 } квартал`
    }
  }


  setTimeout(() => {
    if(currentReport.value.status === 0 || currentReport.value.status === 2){
      emit('close')
      emit('success')
    } else {
      isShowModal.value = true
    }
  }, 0)
})



const quarterList = ref([
  {
    id: 4,
    label: '4 квартал'
  },
  {
    id: 5,
    label: 'Год'
  }
])

const quarterSelected = ref({
  id: 0,
  label: '-'
})

watch(() => quarterSelected.value, () => {
  currentReport.value = reports.value[quarterSelected.value.id - 1]
  form.questions = currentReport.value.distribution
})

const sendForm = async() => {
  let errorFlag = false

  for (let item of form.questions) {
    item.error = ''
  }
  summError.value = ''
  for (let item of form.questions) {
    if(item.tonnage === ''){
      item.error = 'Заполните поле или поставьте 0'
      errorFlag = true
    }
  }
  if(currentReport.value.tonnage > Number(summ.value)){
    summError.value = `Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Распределите еще ${(currentReport.value.tonnage - Number(summ.value)).toFixed(3)} тн`
    errorFlag = true
  }
  if(currentReport.value.tonnage < Number(summ.value)){
    summError.value = `Сумма тоннажа по видам отходов не совпадает с итоговым тоннажом. Превышает тоннаж за выбранный период на ${(Number(summ.value) - currentReport.value.tonnage).toFixed(3)} тн`
    errorFlag = true
  }

  if(!errorFlag){
    isPreloader.value = true

    const req = await useApi('post', `company/report/set_value`, {
      distribution: form.questions.map(q => {
        return {
          ...q,
          tonnage: Number(q.tonnage)
        }
      }),
      id: currentReport.value.id
    })

    isPreloader.value = false

    emit('close')
    emit('success')
  }
}
</script>

<style scoped lang="scss">
  .close-modal{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .quartal-title{
    margin-bottom: 30px;
    span{
      display: block;
      &:first-child{
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.01em;
        color: #212121;
        margin-bottom: 12px;
        font-weight: 600;
      }
      &:last-child{
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
      }
    }
  }
  :deep(.mpz-modal__window){
    width: 680px;
    padding: 30px;
  }
  :deep(.mpz-modal__content){
    max-height: calc(100vh - 120px);
  }
  .title-3{
    margin-bottom: 20px;
  }
  .subtitle{
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.01em;
    opacity: 0.5;
    margin-bottom: 30px;
  }
  .select{
    width: 160px;
    margin-bottom: 30px;
  }
  .period{
    margin-bottom: 30px;
  }
  .question-list{
    border-bottom: 1px solid #A1A1A1;
    max-height: calc(100vh - 560px);
    overflow: auto;
    gap: 20px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .question-item{
    padding: 30px;
    gap: 20px;
    border: 1px solid #EAEAEA;
    border-radius: 30px;
    &__title{
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 6px;
    }
    &__code{
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: rgba(22, 22, 22, 0.5);
      margin-bottom: 20px;
      input{
        border: none;
        font-size: 14px;
        line-height: 17px;
        color: rgba(22, 22, 22, 0.5);
        background: transparent;
      }
    }
  }
  .tonnage-error{
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 130%;
    color: #E37E7E;
  }

  .no-data{
    padding: 30px;
    border: 1px solid #EAEAEA;
    border-radius: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-3{
      color: #161616;
      opacity: 0.45;
      margin-bottom: 30px;
      text-align: center;
    }
  }
</style>
